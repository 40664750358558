import { flushServerSideUsedSvg } from 'yoda-core-components/lib/SvgPortal/SvgPortal';
import RestClient from 'yoda-interfaces/lib/RestClient/RestClient';
import Components from './Components';

class Common {
    /**
     * initializing redux store with default value
     */
    store = {};

    addClientContext = (context) => ({ isBrowser: true, ...context });

    constructor() {
        try {
            /* istanbul ignore next */
            if (__SERVER__ && global && global.$yoda.contextAggregator) {
                RestClient.setContext(global.$yoda.contextAggregator);
            } else {
                RestClient.setContext(this.addClientContext(window.__PRELOADED_STATE__.context));
            }
        } catch (e) {
            console.log('Common Comp:: no context set');
        }

        this.commonComponents = Components;
        this.flushServerSideUsedSvg = flushServerSideUsedSvg; // added method to be able to flush external components icons on app-shell
    }

    /**
     * getComponent will return the component so if any props needed to be
     * passed will be handled from the consumer of this function
     * @param name (Mandatory) name of the components needs to be fetched
     */
    getComponent(name) {
        let returnComponent = null;
        /* istanbul ignore next */
        if (!name) {
            console.warn('Common Comp:: component name is mandatory');
            return null;
        }
        try {
            const customComponent = this.commonComponents[name];
            /* istanbul ignore next */
            if (customComponent) {
                returnComponent = customComponent;
            } else {
                console.warn('Common Comp:: unable to find the component');
                returnComponent = null;
            }
        } catch (e) {
            console.warn('Common Comp:: unable to find the component');
        }
        return returnComponent;
    }
}

export default new Common();
